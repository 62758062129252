import axios from "axios";
import { API_NAPCLASS } from "config/api.config";
import userDetails from "utils/userDetails";

const { token, user_id } = userDetails;

export const getStudentsByFamily = async () => {
  let res = await axios.get(`${API_NAPCLASS}/family/son/${user_id}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getAttendanceByCourseStudent = async (courseId: string, studentId: string) => {
  let res = await axios.get(`${API_NAPCLASS}/family/assistance/${courseId}/${studentId}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getRatingsByCourseStudent = async (courseId: string, studentId: string) => {
  let res = await axios.get(`${API_NAPCLASS}/family/calification/${courseId}/${studentId}`, {
    headers: { Authorization: token },
  });
  return res.data;
};
