import axios from "axios";
import { API_NAPCLASS, API_POSTULATION } from "config/api.config";
import user from "utils/userDetails";

export const getPostulations = async () => {
  let res = await axios.get(`${API_POSTULATION}/show-users/${user.school}`);
  return res.data;
};

export const acceptNapClass = async (school: string, data: Object) => {
  let res = await axios.post(`${API_NAPCLASS}/accept-student/${school}`, data);
  return res.data;
};

export const acceptNapMatricula = async (cod: string, data: Object) => {
  let res = await axios.post(`${API_POSTULATION}/accept-post/${cod}`, data);
  return res.data;
};
