// material-ui
import { makeStyles } from "@mui/styles";
import { AppBar, Grid, Avatar, Box, ButtonBase, Hidden } from "@mui/material";
// project imports
import ProfileSection from "./ProfileSection";
import NotificationSection from "./NotificationSection";
import ChangeSchool from "./ChangeSchool";
import BorderLeftIcon from "@mui/icons-material/BorderLeft";
// assets
import MenuIcon from "@mui/icons-material/Menu";
import userDetails from "utils/userDetails";
import { PropsStyles } from "interfaces";

// style constant
const useStyles = makeStyles((theme: PropsStyles) => ({
  headerAvatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    transition: "all .2s ease-in-out",
    background: "#fff",
    color: theme.palette.primary.dark,
    "&:hover": {
      background: theme.palette.primary.dark,
      color: "#fff",
    },
  },
}));

//-----------------------|| MAIN NAVBAR / HEADER ||-----------------------//

const Header = ({ handleLeftDrawerToggle, open }: { handleLeftDrawerToggle: Function; open: boolean }) => {
  const classes = useStyles(true);
  const { rol } = userDetails;

  return (
    <AppBar style={{ paddingLeft: open ? 240 : 0 }}>
      <Box p={2}>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <ButtonBase>
                  <Avatar
                    variant="rounded"
                    className={classes.headerAvatar}
                    onClick={(e) => handleLeftDrawerToggle(e)}
                    alt="Icon Menu"
                  >
                    {open ? <BorderLeftIcon /> : <MenuIcon />}
                  </Avatar>
                </ButtonBase>
              </Grid>
              <Hidden smDown>
                {(rol === "superadmin" || rol === "teacher" || rol === "admin") && (
                  <Grid item>
                    <ChangeSchool />
                  </Grid>
                )}
              </Hidden>

              <Grid item>
                {/* Cambiar de año */}
                <NotificationSection />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <ProfileSection />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </AppBar>
  );
};

export default Header;
