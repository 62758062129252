import PersonIcon from "@mui/icons-material/Person";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import DateRangeIcon from "@mui/icons-material/DateRange";
import PeopleIcon from "@mui/icons-material/People";
import NotesIcon from "@mui/icons-material/Notes";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import ChatIcon from "@mui/icons-material/Chat";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EventNoteIcon from "@mui/icons-material/EventNote";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";

//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//

const itemsNav = [
  {
    id: "menu",
    type: "group",
    title: "GENERAL",
    roles: ["superadmin", "admin", "teacher", "student", "family", "coordinator", "assistance"],
    children: [
      {
        id: "inicio",
        title: "Inicio",
        type: "item",
        url: "/",
        roles: ["superadmin", "admin", "teacher", "student", "family", "coordinator", "assistance"],
        icon: DashboardIcon,
      },
      {
        id: "schools",
        title: "Colegios",
        type: "item",
        url: "/schools",
        roles: ["superadmin"],
        icon: HomeWorkIcon,
      },
      {
        id: "rooms",
        title: "Aulas",
        type: "item",
        url: "/rooms",
        roles: ["superadmin", "admin"],
        icon: MeetingRoomIcon,
      },
      {
        id: "courses",
        title: "Cursos",
        type: "item",
        url: "/courses",
        roles: ["superadmin", "admin"],
        icon: LibraryBooksIcon,
      },
      {
        id: "postulations",
        title: "Postulaciones",
        type: "item",
        url: "/postulations",
        roles: ["superadmin", "admin"],
        icon: PersonAddIcon,
      },
    ],
  },
  {
    id: "reports",
    type: "group",
    title: "REPORTES",
    roles: ["superadmin", "admin", "assistance", "coordinator"],
    children: [
      {
        id: "assistance",
        title: "Asistencia General",
        type: "item",
        url: "/assistance-general",
        roles: ["superadmin", "admin", "assistance", "coordinator"],
        icon: EventNoteIcon,
      },
      {
        id: "reports",
        title: "Reportes",
        type: "item",
        url: "/report",
        roles: ["superadmin", "admin", "assistance", "coordinator"],
        icon: ViewComfyIcon,
      },
    ],
  },
  {
    id: "mychildren",
    type: "group",
    title: "MIS HIJOS",
    roles: ["family"],
    children: [
      {
        id: "mychildren",
        title: "Mis Hijos",
        type: "item",
        url: "/family/my-children",
        icon: LibraryBooksIcon,
        roles: ["family"],
      },
    ],
  },
  {
    id: "academicgestion",
    type: "group",
    title: "GESTIÓN ACADÉMICA",
    roles: ["teacher", "student", "coordinator", "admin"],
    children: [
      {
        id: "calendar",
        title: "Calendario",
        type: "item",
        url: "/calendar",
        icon: CalendarTodayIcon,
        roles: ["teacher", "student"],
      },
      {
        id: "mycoursesteacher",
        title: "Mis Cursos",
        type: "item",
        url: "/teacher/courses",
        icon: LibraryBooksIcon,
        roles: ["teacher", "admin", "superadmin"],
      },
      {
        id: "mycoursescoordinator",
        title: "Cursos",
        type: "item",
        url: "/coordinator/courses",
        icon: LibraryBooksIcon,
        roles: ["coordinator"],
      },
      {
        id: "mycoursesstudent",
        title: "Mis Cursos",
        type: "item",
        url: "/student/courses",
        icon: LibraryBooksIcon,
        roles: ["student"],
      },
      {
        id: "pending",
        title: "Pendientes",
        type: "item",
        url: "/pending",
        icon: AccessTimeIcon,
        roles: ["teacher", "student"],
      },
    ],
  },
  {
    id: "config",
    type: "group",
    title: "CONFIGURACIÓN",
    roles: ["superadmin", "admin"],
    children: [
      {
        id: "assigment",
        title: "Asignación",
        type: "item",
        url: "/assigment",
        roles: ["superadmin", "admin"],
        icon: AltRouteIcon,
      },
      {
        id: "periods",
        title: "Periodos",
        type: "item",
        url: "/periods",
        roles: ["superadmin", "admin"],
        icon: DateRangeIcon,
      },
      {
        id: "units",
        title: "Unidades",
        type: "item",
        url: "/units",
        roles: ["superadmin", "admin"],
        icon: AcUnitIcon,
      },
      {
        id: "noterange",
        title: "Rango de Notas",
        type: "item",
        url: "/noterange",
        roles: ["superadmin", "admin"],
        icon: NotesIcon,
      },
      {
        id: "competences",
        title: "Competencias",
        type: "item",
        url: "/competences",
        roles: ["superadmin", "admin"],
        icon: FactCheckIcon,
      },
      {
        id: "coursesgroup",
        title: "Areas de Desarrollo",
        type: "item",
        url: "/courses/group",
        roles: ["superadmin", "admin"],
        icon: FactCheckIcon,
      },
    ],
  },
  {
    id: "social",
    type: "group",
    title: "SOCIAL",
    roles: ["superadmin", "admin", "teacher", "student", "family", "coordinator"],
    children: [
      {
        id: "notices",
        title: "Noticias",
        type: "item",
        url: "/notices",
        roles: ["superadmin", "admin", "teacher", "student", "family", "coordinator"],
        icon: RecordVoiceOverIcon,
      },
      {
        id: "messages",
        title: "Mensajes",
        type: "item",
        url: "/messages",
        roles: ["superadmin", "admin", "teacher", "student", "family", "coordinator"],
        icon: ChatIcon,
      },
    ],
  },
  {
    id: "users",
    type: "group",
    title: "USUARIOS",
    roles: ["superadmin", "admin"],
    children: [
      {
        id: "people",
        title: "Usuarios",
        type: "collapse",
        roles: ["superadmin", "admin"],
        icon: PeopleIcon,
        children: [
          {
            id: "admin",
            title: "Administrador",
            type: "item",
            url: "/users/admin",
            roles: ["superadmin"],
            icon: PersonIcon,
          },
          {
            id: "teacher",
            title: "Profesor",
            type: "item",
            url: "/users/teacher",
            roles: ["superadmin", "admin"],
            icon: PersonIcon,
          },
          {
            id: "family",
            title: "Familia",
            type: "item",
            url: "/users/family",
            roles: ["superadmin", "admin"],
            icon: PersonIcon,
          },
          {
            id: "student",
            title: "Alumnos",
            type: "item",
            url: "/users/student",
            roles: ["superadmin", "admin"],
            icon: PersonIcon,
          },
          {
            id: "director",
            title: "Director",
            type: "item",
            url: "/users/director",
            roles: ["superadmin", "admin"],
            icon: PersonIcon,
          },
          {
            id: "psicologa",
            title: "Psicologo",
            type: "item",
            url: "/users/psicologa",
            roles: ["superadmin", "admin"],
            icon: PersonIcon,
          },
          {
            id: "asistencia",
            title: "Asistencia",
            type: "item",
            url: "/users/assistance",
            roles: ["superadmin", "admin"],
            icon: PersonIcon,
          },
          {
            id: "coordinator",
            title: "Coordinador",
            type: "item",
            url: "/users/coordinator",
            roles: ["superadmin", "admin"],
            icon: PersonIcon,
          },
          {
            id: "librarian",
            title: "Bibliotecario",
            type: "item",
            url: "/users/librarian",
            roles: ["superadmin", "admin"],
            icon: PersonIcon,
          },
          {
            id: "nurse",
            title: "Enfermera",
            type: "item",
            url: "/users/nurse",
            roles: ["superadmin", "admin"],
            icon: PersonIcon,
          },
        ],
      },
    ],
  },
];

export default itemsNav;
