import axios from "axios";
import { API_NAPCLASS } from "config/api.config";
import userDetails from "utils/userDetails";

const { token } = userDetails;

export const getAttendance = async (courseID: string) => {
  let res = await axios.get(`${API_NAPCLASS}/assistance/${courseID}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const postAttendance = async (data: object, courseID: string) => {
  let res = await axios.post(`${API_NAPCLASS}/assistance/${courseID}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getDetailAttendance = async (
  courseID: string,
  day: string,
  month: string,
  year: string
) => {
  let res = await axios.get(
    `${API_NAPCLASS}/assistance/${courseID}/${day}/${month}/${year}`,
    { headers: { Authorization: token } }
  );
  return res.data;
};

export const putAttendance = async (
  courseID: string,
  day: string,
  month: string,
  year: string,
  data: Object
) => {
  let res = await axios.put(
    `${API_NAPCLASS}/assistance/${courseID}/${day}/${month}/${year}`,
    data,
    { headers: { Authorization: token } }
  );
  return res.data;
};
