import axios from "axios";
import { API_NAPCLASS } from "config/api.config";
import userDetails from "utils/userDetails";

const { token } = userDetails;

export const getEvent = async (idEvent: string, idStudent: string) => {
  const res = await axios.get(`${API_NAPCLASS}/task/validate/${idEvent}/${idStudent}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const createTask = async (formData: any, idStudent: string) => {
  const res = await axios.post(`${API_NAPCLASS}/task/${idStudent}`, formData, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const updateTask = async (formData: any, taskId: string) => {
  const res = await axios.put(`${API_NAPCLASS}/task/${taskId}`, formData, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getEventQualification = async (idEvent: string) => {
  const res = await axios.get(`${API_NAPCLASS}/task/list-students/${idEvent}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

// Participations
export const getParticipations = async (
  idCourse: string,
  day: number,
  month: number,
  year: number
) => {
  const res = await axios.get(`${API_NAPCLASS}/participation/${idCourse}/${day}/${month}/${year}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const updateParticipations = async (formData: any, idEvent: string) => {
  const res = await axios.put(`${API_NAPCLASS}/task/list-participations/${idEvent}`, formData, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const updateUpParticipations = async (
  idCourse: string,
  idStudent: string,
  day: number,
  month: number,
  year: number
) => {
  const res = await axios.get(
    `${API_NAPCLASS}/participation/add-point/${idCourse}/${idStudent}/${day}/${month}/${year}`,
    {
      headers: { Authorization: token },
    }
  );
  return res.data;
};

export const updateDownParticipations = async (
  idCourse: string,
  idStudent: string,
  day: number,
  month: number,
  year: number
) => {
  const res = await axios.get(
    `${API_NAPCLASS}/participation/substract-point/${idCourse}/${idStudent}/${day}/${month}/${year}`,
    {
      headers: { Authorization: token },
    }
  );
  return res.data;
};
