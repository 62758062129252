const formatRole = (rol: string) => {
  let role = "";
  switch (rol) {
    case "superadmin":
      role = "SUPERADMIN";
      break;
    case "student":
      role = "ALUMNO";
      break;
    case "family":
      role = "FAMILIA";
      break;
    case "teacher":
      role = "PROFESOR";
      break;
    case "admin":
      role = "ADMINISTRADOR";
      break;
    case "director":
      role = "DIRECTOR";
      break;
    case "psicologa":
      role = "PSICOLOGO";
      break;
    case "coordinator":
      role = "COORDINADOR";
      break;
    case "librarian":
      role = "BIBLIOTECARIO";
      break;
    case "nurse":
      role = "ENFERMERA";
      break;
    case "assistance":
      role = "ASISTENCIA";
      break;

    default:
      role = "NO DEFINIDO";
      break;
  }

  return role;
};
export default formatRole;
