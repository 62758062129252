import axios from "axios";
import { API_NAPCLASS } from "config/api.config";
import user from "utils/userDetails";

export const getRooms = async () => {
  let res = await axios.get(`${API_NAPCLASS}/room/${user.school}/${user.year}`, {
    headers: { Authorization: user.token },
  });
  return res.data.reverse();
};

export const getRoomById = async (roomID: string) => {
  let res = await axios.get(`${API_NAPCLASS}/room/id/${roomID}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const postRooms = async (data: Object) => {
  let res = await axios.post(`${API_NAPCLASS}/room/${user.school}/${user.year}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const putRooms = async (data: Object, idRoom: string) => {
  let res = await axios.put(`${API_NAPCLASS}/room/${idRoom}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const uploadRooms = async (data: Object) => {
  let res = await axios.post(`${API_NAPCLASS}/room/import/${user.school}/${user.year}`, data, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getStudentsByRoom = async (roomID: string) => {
  const res = await axios.get(`${API_NAPCLASS}/room/students/${roomID}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getStudentsByRoomR = async (roomID: string) => {
  const res = await axios.get(`${API_NAPCLASS}/room/students-r/${roomID}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};

export const getStudentsByRoomExcel = async (roomID: string) => {
  const res = await axios.get(`${API_NAPCLASS}/room/students/excel/${roomID}`, {
    headers: { Authorization: user.token },
  });
  return res.data;
};
