import StorageService from "auth/StorageService";
import { SESSION_MODE } from "config/session";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";

interface ContextProps {
  mode: "light" | "dark";
  setMode: Function;
}

export const NapClass = createContext({} as ContextProps);

export const useNapClassContext = () => useContext(NapClass);

const NapClassProvider = ({ children }: { children: ReactNode }) => {
  const [mode, setMode] = useState<"light" | "dark">("light");

  useEffect(() => {
    const mode = StorageService.get(SESSION_MODE);
    setMode(mode ? JSON.parse(mode) : "light");
  }, []);

  return <NapClass.Provider value={{ mode, setMode }}>{children}</NapClass.Provider>;
};
export default NapClassProvider;
