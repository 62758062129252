import { useRef, useState, Fragment, useEffect } from "react";
// material-ui
import {
  Avatar,
  Menu,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
  Typography,
  FormHelperText,
  Fab,
  Box,
} from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import moment from "moment";
import userDetails from "utils/userDetails";

// third-party
import AuthenticationService from "auth/AuthenticationService";

// assets
import SettingsIcon from "@mui/icons-material/Settings";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import PersonIcon from "@mui/icons-material/Person";
import { Link } from "react-router-dom";
import { validToken } from "requests";
import { notification } from "antd";
import DarkMode from "./DarkMode";

//-----------------------|| PROFILE MENU ||-----------------------//

const ProfileSection = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleLogout = async () => {
    let { url } = userDetails;
    AuthenticationService.logout()
      .then(() => {
        window.location.href = `/${url}/login`;
      })
      .catch(() => {
        window.location.reload();
      });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const prevOpen = useRef(open);

  const getSaludo = () => {
    const hour = moment().hour();
    if (hour > 17) return "Buenas noches";
    if (hour > 11) return "Buenas tardes";
    return "Buenos días";
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      // anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    getValidationToken();
    // eslint-disable-next-line
  }, []);

  const getValidationToken = async () => {
    try {
      let res = await validToken();
      if (res.message === false) {
        notification["info"]({
          message: "Su sesión expiró.",
          description: "Es necesario volver a iniciar sesión. NAPCLASS",
        });
        setTimeout(() => {
          handleLogout();
        }, 2000);
      }
    } catch (error) {
      notification["error"]({
        message: "No estamos conectados a la red.",
        description: "Comprueba tu conexión a internet.",
      });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Fab variant="extended" size="small" onClick={handleClick}>
        <Avatar
          src={userDetails.profile_picture ? userDetails.profile_picture : "/logo192.png"}
          aria-controls={open ? "menu-list-grow" : undefined}
        />
        <SettingsIcon sx={{ ml: 1 }} color="primary" />
      </Fab>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box pl={2} pr={2}>
          <Typography variant="subtitle1">
            <b>{getSaludo()}</b>
            <FormHelperText>
              {userDetails.f_name} {userDetails.l_name}
            </FormHelperText>
          </Typography>
        </Box>
        <Divider />
        <Box>
          <List component="nav" style={{ width: 200 }}>
            <ListItemButton component={Link} to={{ pathname: "/profile" }}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="body2">Cuenta</Typography>} />
            </ListItemButton>
            <ListItemButton onClick={handleLogout}>
              <ListItemIcon>
                <PowerSettingsNewIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="body2">Salir</Typography>} />
            </ListItemButton>
            <ListItemButton>
              <ListItemIcon>
                <DarkMode />
              </ListItemIcon>
            </ListItemButton>
          </List>
        </Box>
      </Menu>
    </Fragment>
  );
};

export default ProfileSection;
