import { Avatar, Stack, Typography } from "@mui/material";
import { Select, Spin } from "antd";

const { Option } = Select;

interface TypeProps {
  value: string;
  onChange: Function;
  data: Array<PropsSelect>;
  loading: boolean;
  copyStyles?: boolean;
}

interface PropsSelect {
  _id: string;
  name: string;
  tradeName: string;
  logo: string;
  url: string;
}

const SelectSchools = ({ value, onChange, data, loading, copyStyles = true }: TypeProps) => {
  const removeAccents = (str: string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const filterOptions = (inputValue: string, option: any) => {
    const title = removeAccents(option.props.title.toLowerCase());
    return title.includes(inputValue.toLowerCase());
  };

  return (
    <Spin spinning={loading}>
      <Select
        value={value}
        onChange={(e) => onChange(e)}
        loading={loading}
        size="large"
        filterOption={filterOptions}
        showSearch
        className={copyStyles ? "select-schools" : ""}
        style={{ width: "100%" }}
      >
        {data.map((row, index) => (
          <Option value={row._id} key={index} title={`${row.url} ${row.tradeName}`}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Avatar src={row.logo} alt={row.url} />
              <Typography variant="caption">{row.url}</Typography>
              <Typography variant="body1" color="inherit">
                {row.tradeName}
              </Typography>
            </Stack>
          </Option>
        ))}
      </Select>
    </Spin>
  );
};

export default SelectSchools;
