import axios from "axios";
import { API_NAPCLASS } from "config/api.config";
import userDetails from "utils/userDetails";

const { token } = userDetails;

export const getPlan = async (course: string | undefined) => {
  let res = await axios.get(`${API_NAPCLASS}/course/thematic-plan/${course}`, {
    headers: { Authorization: token },
  });
  return res.data;
};
