import axios from "axios";
import { API_NAPCLASS } from "config/api.config";
import userDetails from "utils/userDetails";

const { school, year, token } = userDetails;

export const getNotices = async () => {
  let res = await axios.get(`${API_NAPCLASS}/notice/${school}/${year}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const postNotices = async (data: Object) => {
  let res = await axios.post(`${API_NAPCLASS}/notice/${school}/${year}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const putNotices = async (data: Object, id: string) => {
  let res = await axios.put(`${API_NAPCLASS}/notice/${id}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};
