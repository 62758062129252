import axios from "axios";
import { API_NAPCLASS } from "config/api.config";
import userDetails from "utils/userDetails";

const { year, token, school } = userDetails;

export type ReportType = "assistance" | "participation" | "exams" | "tasks";

export const getReports = async (type: ReportType, period: String, id: String) => {
  const res = await axios.get(`${API_NAPCLASS}/report/${type}/${period}/${id}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const exportReports = async (type: ReportType, period: String, id: String) => {
  const res = await axios.get(`${API_NAPCLASS}/report/export/${type}/${period}/${id}`, {
    headers: { Authorization: token },
    responseType: "blob",
  });
  return res.data;
};

export const getAssistanceGeneral = async (month: string, year: string, schoolId: string) => {
  const res = await axios.get(`${API_NAPCLASS}/report/assistance/${month}/${year}/${schoolId}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getReportCardCompetencesPeriod = async (studentId: string, periodId: string) => {
  const res = await axios.get(`${API_NAPCLASS}/notepad/notepad/${studentId}/${periodId}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getReportCardCompetencesYear = async (studentId: string) => {
  const res = await axios.get(`${API_NAPCLASS}/notepad/notepad-anual/${studentId}/${school}/${year}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getReportPeriodByRoom = async (roomId: string, periodId: string) => {
  const res = await axios.get(`${API_NAPCLASS}/notepad/notepad-room/${roomId}/${periodId}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getReportAnualRoom = async (roomId: string, data: any) => {
  const res = await axios.post(`${API_NAPCLASS}/notepad/notepad-room-anual/${roomId}/${year}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getReportMerit = async (roomId: string, periodId: string) => {
  const res = await axios.get(`${API_NAPCLASS}/report/order/${roomId}/${periodId}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getReportNotas = async (roomId: string) => {
  const res = await axios.get(`${API_NAPCLASS}/report/finishedNotes/${roomId}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getReportCardConsolidatedCompetences = async (roomId: string, periodId: string) => {
  const res = await axios.get(`${API_NAPCLASS}/notepad/notepads-period/${roomId}/${periodId}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getReportCardConsolidatedCompetencesAreas = async (roomId: string, periodId: string) => {
  const res = await axios.get(`${API_NAPCLASS}/notepad/notepads-period-area/${roomId}/${periodId}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getReportCardConsolidatedTransversal = async (roomId: string, periodId: string) => {
  const res = await axios.get(`${API_NAPCLASS}/notepad/notepads-transversal-competencies/${roomId}/${periodId}`, {
    headers: { Authorization: token },
  });
  return res.data;
};
