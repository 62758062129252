import axios from "axios";
import { API_NAPCLASS } from "config/api.config";
import userDetails from "utils/userDetails";

const { school, year, token } = userDetails;

export const getPeriods = async () => {
  let res = await axios.get(
    `${API_NAPCLASS}/academic-period/${school}/${year}`,
    { headers: { Authorization: token } }
  );
  return res.data;
};

export const postPeriods = async (data: Object) => {
  let res = await axios.post(
    `${API_NAPCLASS}/academic-period/${school}/${year}`,
    data,
    { headers: { Authorization: token } }
  );
  return res.data;
};

export const putPeriods = async (data: Object, id: string) => {
  let res = await axios.put(`${API_NAPCLASS}/academic-period/${id}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};
