import axios from "axios";
import { API_NAPCLASS } from "config/api.config";
import userDetails from "utils/userDetails";

const { school, year, token, user_id } = userDetails;

export const getCourses = async () => {
  let res = await axios.get(`${API_NAPCLASS}/course/${school}/${year}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getCourseById = async (courseID: string) => {
  let res = await axios.get(`${API_NAPCLASS}/course/id/${courseID}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const postCourses = async (data: string) => {
  let res = await axios.post(`${API_NAPCLASS}/course/${school}/${year}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const putCourses = async (data: Object, idCourse: string) => {
  let res = await axios.put(`${API_NAPCLASS}/course/${idCourse}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const uploadCourses = async (data: Object) => {
  let res = await axios.post(`${API_NAPCLASS}/course/import/${school}/${year}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getCoursesToAssign = async (data: Object) => {
  let res = await axios.post(`${API_NAPCLASS}/course/grade-level/${school}/${year}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const assignCoursesToRoom = async (data: Object, roomId: string) => {
  let res = await axios.put(`${API_NAPCLASS}/room/assign-courses/${roomId}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getCoursesByTeacher = async (schoolId = school) => {
  let res = await axios.get(`${API_NAPCLASS}/teacher/courses/${user_id}/${schoolId}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getCoursesByStudent = async (studentId = user_id) => {
  let res = await axios.get(`${API_NAPCLASS}/course/${studentId}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const updateZoom = async (data: Object, courseid: string) => {
  let res = await axios.put(`${API_NAPCLASS}/course/zoom-link/${courseid}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const updateImageColor = async (data: Object, courseid: string) => {
  let res = await axios.put(`${API_NAPCLASS}/course/customization/${courseid}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const postStage = async (data: Object, unitID: string | undefined) => {
  let res = await axios.post(`${API_NAPCLASS}/stage-unit/${unitID}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const putStage = async (data: Object, unitID: string) => {
  const res = await axios.put(`${API_NAPCLASS}/stage-unit/${unitID}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getParticipants = async (courseID: string) => {
  let res = await axios.get(`${API_NAPCLASS}/room/participants/${courseID}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getStudents = async (courseId: string) => {
  let res = await axios.get(`${API_NAPCLASS}/room/students/${courseId}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getStudentsByRoom = async (roomID: string) => {
  let res = await axios.get(`${API_NAPCLASS}/room/students-r/${roomID}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

// EVENTS

export const postEvents = async (data: Object) => {
  let res = await axios.post(`${API_NAPCLASS}/event/${school}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const putEvents = async (data: Object, eventId: string) => {
  let res = await axios.put(`${API_NAPCLASS}/event/${eventId}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getEvents = async (type: string, stage: string) => {
  let res = await axios.get(`${API_NAPCLASS}/event/${type}/${stage}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getEventByMonth = async (type: string, course: string, month: string) => {
  let res = await axios.get(`${API_NAPCLASS}/event/${type}/${course}/${month}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

// duplicate

export const duplicateEvents = async (eventId: string, data: object) => {
  let res = await axios.post(`${API_NAPCLASS}/event/migrate/${eventId}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

// COMPETENCIES

export const getCompetencies = async (courseId: string, year: string) => {
  const res = await axios.get(`${API_NAPCLASS}/competence/groupCourse/${courseId}/${year}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getCompetenciesGroup = async (groupCourseId: string, periodId: string) => {
  const res = await axios.get(`${API_NAPCLASS}/competence/${groupCourseId}/${periodId}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getTransversalCompetencies = async (roomId: string) => {
  const res = await axios.get(`${API_NAPCLASS}/competence/transversal/${roomId}/${year}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const postCompetence = async (courseId: string, year: string, data: object) => {
  let res = await axios.post(`${API_NAPCLASS}/competence/${courseId}/${year}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const postTransversalCompetence = async (roomId: string, data: object) => {
  let res = await axios.post(`${API_NAPCLASS}/competence/transversal/${roomId}/${year}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const putCompetence = async (periodId: string, data: object) => {
  const res = await axios.put(`${API_NAPCLASS}/competence/${periodId}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const deleteCompetence = async (competenceID: string) => {
  const res = await axios.delete(`${API_NAPCLASS}/competence/${competenceID}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const postDuplicateCompetence = async (data: object) => {
  const res = await axios.post(`${API_NAPCLASS}/competence/duplicate/transversal`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const postCompetenceNotes = async (courseId: string, periodId: string, data: object) => {
  const res = await axios.post(`${API_NAPCLASS}/notepad/${courseId}/${periodId}/${year}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const putCompetenceNotes = async (courseId: string, periodId: string, data: object) => {
  const res = await axios.put(`${API_NAPCLASS}/notepad/${courseId}/${periodId}/${year}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const updateOneCompetenceNote = async (data: object, notepadId: string) => {
  const res = await axios.put(`${API_NAPCLASS}/notepad/individual/${notepadId}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getCompetenceNotes = async (courseId: string, year: string) => {
  const res = await axios.get(`${API_NAPCLASS}/notepad/${courseId}/${year}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

// GROUPING COURSES

export const getCoursesGC = async () => {
  const res = await axios.get(`${API_NAPCLASS}/groupcourse/${school}/${year}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getFreeCoursesGC = async () => {
  const res = await axios.get(`${API_NAPCLASS}/groupcourse/courses-free/${school}/${year}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getCoursesGCById = async (coursegroupId: string) => {
  const res = await axios.get(`${API_NAPCLASS}/groupcourse/${coursegroupId}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const postCourseGroup = async (data: object) => {
  const res = await axios.post(`${API_NAPCLASS}/groupcourse/${school}/${year}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const putCourseGroup = async (data: object, coursegroupId: string) => {
  const res = await axios.put(`${API_NAPCLASS}/groupcourse/${coursegroupId}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const deleteCourseGroup = async (coursegroupId: string) => {
  const res = await axios.delete(`${API_NAPCLASS}/groupcourse/${coursegroupId}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const assignCoursesGroup = async (data: object, coursegroupId: string) => {
  const res = await axios.put(`${API_NAPCLASS}/groupcourse/assign/${coursegroupId}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};
