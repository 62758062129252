import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { TakeExamProps } from "interfaces";
import StorageService from "auth/StorageService";
import { SESSION_EXAM } from "config/session";

export const Exam = createContext({} as TakeExamProps);

export const useExamContext = () => useContext(Exam);

const ExamProvider = ({ children }: { children: ReactNode }) => {
  const [redirect, setRedirect] = useState(false);
  const [event, setEvent] = useState({});
  const [questions, setQuestions] = useState([]);
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (StorageService.get(SESSION_EXAM)) setRedirect(true);
  }, []);

  const resetExam = () => {
    setRedirect(false);
    setEvent({});
    setQuestions([]);
    setStep(0);
    StorageService.remove(SESSION_EXAM);
  };

  return (
    <Exam.Provider
      value={{
        redirect,
        setRedirect,
        event,
        setEvent,
        questions,
        setQuestions,
        step,
        setStep,
        resetExam,
      }}
    >
      {children}
    </Exam.Provider>
  );
};
export default ExamProvider;
