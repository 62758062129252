import React from "react";

// material-ui
import { makeStyles } from "@mui/styles";
import { Collapse, List, Box, ListItemText, Typography } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";

// project imports
import NavItem from "./../NavItem";

// assets
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { PropsStyles } from "interfaces";

// style constant
const useStyles = makeStyles((theme: PropsStyles) => ({
  collapseIcon: {
    fontSize: "1rem",
    marginTop: "auto",
    marginBottom: "auto",
  },
  collapseIconSub: {
    fontSize: "1rem",
    marginTop: "auto",
    marginBottom: "auto",
  },
  menuIcon: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  listIcon: {
    minWidth: "18px",
    marginTop: "auto",
    marginBottom: "auto",
  },
  listCustomIconSub: {
    width: "6px",
    height: "6px",
  },
  listCustomIconSubActive: {
    width: "8px",
    height: "8px",
  },
  listItem: {
    marginBottom: "5px",
    alignItems: "flex-start",
  },
  listItemNoBack: {
    marginBottom: "5px",
    backgroundColor: "transparent !important",
    paddingTop: "8px",
    paddingBottom: "8px",
    alignItems: "flex-start",
  },
  subMenuCaption: {
    ...theme.typography.subMenuCaption,
  },
  collapseWrapper: {
    position: "relative",
    "&:after": {
      content: "''",
      position: "absolute",
      left: "32px",
      top: 0,
      height: "100%",
      width: "1px",
      opacity: 1,
      background: theme.palette.primary.light,
    },
  },
}));

//-----------------------|| SIDEBAR MENU LIST COLLAPSE ITEMS ||-----------------------//

interface PropTypes {
  menu: {
    children: Object[];
    id: string;
    title: string;
    icon: any;
  };
  level: number;
}

const NavCollapse = ({ menu, level }: PropTypes) => {
  const classes: any = useStyles(true);

  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<string | null>(null);

  const handleClick = () => {
    setOpen(!open);
    setSelected(!selected ? menu.id : null);
  };

  // menu collapse & item
  const menus = menu.children.map((item: any) => {
    switch (item.type) {
      case "collapse":
        return <NavCollapse key={item.id} menu={item} level={level + 1} />;
      case "item":
        return <NavItem key={item.id} item={item} level={level + 1} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  const Icon = menu.icon;
  const menuIcon = menu.icon ? (
    <Icon stroke={1.5} size="1.3rem" className={classes.listCustomIcon} />
  ) : (
    <FiberManualRecordIcon
      style={{ width: 10, height: 10 }}
      // fontSize={level > 0 ? "inherit" : "default"}
      color="action"
    />
  );

  return (
    <React.Fragment>
      <ListItemButton
        selected={selected === menu.id}
        onClick={handleClick}
        style={{ paddingLeft: level * 23 + "px" }}
      >
        <Box pr={1}>{menuIcon}</Box>
        <ListItemText
          primary={
            <Typography variant="body2" color="inherit">
              {menu.title}
            </Typography>
          }
        />
        {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          className={classes.collapseWrapper}
        >
          {menus}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

export default NavCollapse;
