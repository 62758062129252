import axios from "axios";
import { API_NAPCLASS } from "config/api.config";
import userDetails from "utils/userDetails";

const { school, token, year } = userDetails;

export const getUsers = async (rol: string) => {
  let res = await axios.get(`${API_NAPCLASS}/user/${rol}/${school}/${userDetails.year}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const postUsers = async (rol: string | undefined, data: Object) => {
  let res = await axios.post(`${API_NAPCLASS}/user/${rol}/${school}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const putUsers = async (data: Object, id: string) => {
  let res = await axios.put(`${API_NAPCLASS}/user/${id}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const putProfileImage = async (data: Object, id: string) => {
  let res = await axios.put(`${API_NAPCLASS}/user/profile-picture/${id}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const uploadUsers = async (rol: string, data: Object, year: number) => {
  let res = await axios.post(`${API_NAPCLASS}/user/import/${rol}/${school}/${year}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const changePassword = async (data: Object) => {
  let res = await axios.put(`${API_NAPCLASS}/auth/change_password`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getStudentsToAssign = async (data: Object) => {
  let res = await axios.post(`${API_NAPCLASS}/student/grade-level/${school}/${year}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const assignStudentsToRoom = async (data: Object, idRoom: string) => {
  let res = await axios.put(`${API_NAPCLASS}/room/assign-students/${idRoom}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getTeachersToAssign = async (teacher: string) => {
  let res = await axios.get(`${API_NAPCLASS}/course/courses-to-assign/${teacher}/${school}/${year}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const assignTeachersToCourse = async (data: Object, teacher: string) => {
  let res = await axios.put(`${API_NAPCLASS}/course/assign-teacher/${teacher}/${userDetails.school}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const changeUserStatus = async (data: Object, userId: string) => {
  let res = await axios.put(`${API_NAPCLASS}/user/activate/${userId}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const resentMail = async (userId: string) => {
  let res = await axios.get(`${API_NAPCLASS}/auth/reset-password/${userId}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const deleteUser = async (userId: string) => {
  let res = await axios.delete(`${API_NAPCLASS}/user/${userId}`, {
    headers: { Authorization: token },
  });
  return res.data;
};
