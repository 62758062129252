class StorageService {
  static set(key: string, data: Object) {
    return localStorage.setItem(key, JSON.stringify(data));
  }

  static get(key: string) {
    let data: any = localStorage.getItem(key);
    return data;
  }

  static remove(key: string) {
    return localStorage.removeItem(key);
  }
}

export default StorageService;
