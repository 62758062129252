import { Select } from "antd";
import moment from "moment-timezone";

const { Option } = Select;

const list: Array<number> = [];

for (let i = 2020; i < moment().year() + 3; i++) {
  list.push(i);
}

const SelectYear = ({ year, setYear }: { year: number; setYear: Function }) => {
  return (
    <Select
      value={year}
      onChange={(e) => setYear(e)}
      size="large"
      className="select-year"
      style={{ width: "100%" }}
    >
      {list.map((el, index) => (
        <Option value={el} key={index} title={`${el}`}>
          {el}
        </Option>
      ))}
    </Select>
  );
};

export default SelectYear;
