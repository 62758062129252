import { useState, useEffect, Fragment, ReactNode } from "react";
// material-ui
import { useTheme } from "@mui/material/styles";
import { Toolbar, useMediaQuery, Paper, Box, Button } from "@mui/material";
import PerfectScrollBar from "react-perfect-scrollbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// project imports
import Header from "./Header";
import Sidebar from "./Sidebar";

import userDetails from "utils/userDetails";

//-----------------------|| MAIN LAYOUT ||-----------------------//

const MainLayout = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();
  let matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const [openSidebar, setOpenSidebar] = useState(matchUpMd);

  useEffect(() => {
    setOpenSidebar(matchUpMd);
  }, [matchUpMd]);

  const toggleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };

  return (
    <Fragment>
      <Toolbar style={{ borderBottom: "1px solid #ddd", zIndex: 999 }}>
        <Header handleLeftDrawerToggle={toggleSidebar} open={openSidebar} />
      </Toolbar>
      <Sidebar drawerOpen={openSidebar} drawerToggle={toggleSidebar} matchUpMd={matchUpMd} />
      <main>
        <PerfectScrollBar style={{ height: "calc((100vh) - 64px)" }}>
          <Paper square style={{ marginLeft: openSidebar ? 240 : 0 }} elevation={0}>
            {children}
          </Paper>
        </PerfectScrollBar>
      </main>

      {userDetails.rol !== "superadmin" && (
        <Box
          sx={{ position: "absolute", bottom: "5px", left: "5px", zIndex: "9999", width: "120px" }}
          onClick={() => window.history.back()}
        >
          <Button variant="contained" fullWidth startIcon={<ArrowBackIcon />}>
            VOLVER
          </Button>
        </Box>
      )}
    </Fragment>
  );
};

export default MainLayout;
