import { Redirect, Route } from "react-router-dom";
import DashboardLayout from "layout";
import Page401 from "pages/Page401";
import userDetails from "utils/userDetails";

export interface TypeProps {
  component: any;
  path: string;
  listRoles: Array<string>;
  isPrivate: boolean;
  exact: boolean;
}

const ApplicationRouter = (props: TypeProps) => {
  const {
    component: Component,
    path,
    listRoles,
    isPrivate,
    exact,
    ...rest
  } = props;

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) =>
        isPrivate && !Boolean(userDetails.token) ? (
          <Redirect to={{ pathname: "/home" }} />
        ) : isPrivate ? (
          listRoles.includes(userDetails.rol) ? (
            <DashboardLayout>
              <Component {...props} />
            </DashboardLayout>
          ) : (
            <Page401 />
          )
        ) : (
          <Component {...props} />
        )
      }
      {...rest}
    />
  );
};

export default ApplicationRouter;
