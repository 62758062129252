import axios from "axios";
import { API_NAPCLASS } from "config/api.config";
import userDetails from "utils/userDetails";

const { school, year, token } = userDetails;

export const validToken = async () => {
  let res = await axios.get(`${API_NAPCLASS}/auth/validToken`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const postSections = async (data: Object) => {
  let res = await axios.post(
    `${API_NAPCLASS}/room/sections/${school}/${year}`,
    data,
    { headers: { Authorization: token } }
  );
  return res.data;
};

export const uploadImageEditor = async (data: Object) => {
  let res = await axios.post(`${API_NAPCLASS}/editor-upload-img`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};
