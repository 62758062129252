import navitems from "./nav-items";
import userDetails from "utils/userDetails";
//-----------------------|| MENU ITEMS ||-----------------------//
interface TypeArray {
  roles: Array<string>;
  children: TypeArray[];
}

interface NewArray {
  children: TypeArray[];
}

const { rol } = userDetails;

let tmpArr: NewArray[] = [];
navitems.forEach((rowOne: any) => {
  if (rowOne.roles.includes(rol)) {
    tmpArr.push({ ...rowOne, children: [] });
    rowOne.children.forEach((rowTwo: TypeArray) => {
      if (rowTwo.roles.includes(rol)) {
        let iOne = tmpArr.length - 1;
        if (rowTwo.children) {
          tmpArr[iOne].children.push({ ...rowTwo, children: [] });
          rowTwo.children.forEach((rowThree: TypeArray) => {
            if (rowThree.roles.includes(rol)) {
              let iTwo = tmpArr[iOne].children.length - 1;
              tmpArr[iOne].children[iTwo].children.push(rowThree);
            }
          });
        } else {
          tmpArr[iOne].children.push(rowTwo);
        }
      }
    });
  }
});

export default tmpArr;
