import { useState, useEffect } from "react";
import SelectYear from "components/selects/SelectYear";
import userDetails from "utils/userDetails";
import { makeStyles } from "@mui/styles";
import { SESSION_USER } from "config/session";
import StorageService from "auth/StorageService";
import { PropsStyles } from "interfaces";
//-----------------------|| NOTIFICATION ||-----------------------//

const useStyles = makeStyles((theme: PropsStyles) => ({
  select: {
    width: 90,
    [theme.breakpoints.down("sm")]: { display: "none" },
  },
}));

const NotificationSection = () => {
  const [year, setYear] = useState(userDetails.year);
  const classes = useStyles(true);

  useEffect(() => {
    if (!Boolean(userDetails.year)) {
      let res: any | null = StorageService.get(SESSION_USER);
      res.value["year"] = 2021;
      StorageService.set(SESSION_USER, res);
      setYear(2021);
    } else {
      setYear(userDetails.year);
    }
    // eslint-disable-next-line
  }, [userDetails]);

  const handleChangeYear = (e: number) => {
    let res: any | null = JSON.parse(localStorage.getItem(SESSION_USER)!);
    console.log("res:", res);
    res.value["year"] = e;
    StorageService.set(SESSION_USER, res);
    setYear(e);
    window.location.reload();
  };

  return (
    <div className={classes.select}>
      <SelectYear year={year} setYear={handleChangeYear} />
    </div>
  );
};

export default NotificationSection;
