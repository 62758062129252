import { useEffect, useState } from "react";
import { notification } from "antd";
import { getSchool } from "requests";

const useSchools = (large = false) => {
  const [schools, setSchools] = useState([]);
  const [load, setLoading] = useState(true);

  useEffect(() => {
    obtainSchools();
    // eslint-disable-next-line
  }, []);

  const obtainSchools = async () => {
    try {
      setLoading(true);
      const res = await getSchool();
      const result = res.map((e: any) => ({
        ...e,
        name: large ? `${e.url} ─ ${e.name} ─ ${e.tradeName}` : e.name,
      }));
      setSchools(result.reverse());
    } catch (error) {
      notification["error"]({ message: `Ocurrió un error al realizar la operación.` });
    } finally {
      setLoading(false);
    }
  };

  return { load, schools, obtainSchools };
};
export default useSchools;
