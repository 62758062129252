import axios from "axios";
import { API_NAPCLASS } from "config/api.config";
import userDetails from "utils/userDetails";

const { rol, token, user_id } = userDetails;

export const getSchoolByUrl = async (url: string) => {
  let res = await axios.get(`${API_NAPCLASS}/school/${url}`);
  return res.data;
};

export const postSchool = async (data: Object) => {
  let res = await axios.post(`${API_NAPCLASS}/school`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const putSchool = async (data: Object, id: string) => {
  let res = await axios.put(`${API_NAPCLASS}/school/${id}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getSchool = async () => {
  const check = rol !== "superadmin";
  const path = `${check ? "/user" : ""}/school${rol !== "superadmin" ? `s/${user_id}` : ""}`;
  let res = await axios.get(`${API_NAPCLASS}${path}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const assignLevelShift = async (data: Object, schoolId: string) => {
  let res = await axios.put(`${API_NAPCLASS}/school/shifts-levels/${schoolId}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};
