import axios from "axios";
import { API_NAPCLASS } from "config/api.config";
import userDetails from "utils/userDetails";

const { token } = userDetails; //school, year,

export const getPendings = async (id: number) => {
  const res = await axios.get(`${API_NAPCLASS}/event/pendings/${id}`, {
    headers: { Authorization: token },
  });
  return res.data;
};
