import axios from "axios";
import { API_NAPCLASS } from "config/api.config";
import userDetails from "utils/userDetails";

const { token } = userDetails; //school, year,

export const postQuestion = async (data: object, type: string, stage: string) => {
  let res = await axios.post(`${API_NAPCLASS}/question/${type}/${stage}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const putQuestion = async (data: object, question: string) => {
  let res = await axios.put(`${API_NAPCLASS}/question/${question}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const deleteQuestion = async (id: string) => {
  let res = await axios.delete(`${API_NAPCLASS}/question/${id}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getQuestions = async (stage: string) => {
  let res = await axios.get(`${API_NAPCLASS}/question/${stage}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const postExam = async (stage: string, data: object) => {
  let res = await axios.post(`${API_NAPCLASS}/event/exam/${stage}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const putExam = async (examId: string, data: object) => {
  let res = await axios.put(`${API_NAPCLASS}/exam/update/${examId}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const addMinutesToExam = async (examId: string, data: object) => {
  let res = await axios.put(`${API_NAPCLASS}/exam/time/${examId}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getExams = async (course: string) => {
  let res = await axios.get(`${API_NAPCLASS}/event/examen/${course}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

// STUDENTS EXAM

export const getExamByStudent = async (event: string, student: string) => {
  let res = await axios.get(`${API_NAPCLASS}/exam/${event}/${student}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

// STUDENT RESPONSE EXAM

export const studentRespondeExam = async (examId: string, data: object) => {
  let res = await axios.post(`${API_NAPCLASS}/exam/response-exam/${examId}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

// GET STUDENTS TU REVIEW EXAM

export const getStudentsToReviewExam = async (examId: string) => {
  let res = await axios.get(`${API_NAPCLASS}/exam/list-students/${examId}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getExamByStudentToReview = async (examId: string) => {
  let res = await axios.get(`${API_NAPCLASS}/exam/detail/${examId}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const qualifyExam = async (examId: string, data: object) => {
  let res = await axios.post(`${API_NAPCLASS}/exam/qualify/${examId}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};
