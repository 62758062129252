import animation from "assets/json/401.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { Grid, Button, Typography } from "@mui/material";

const Page404 = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: "100vh" }}
    >
      <Grid item textAlign="center">
        <Player
          autoplay
          loop
          src={animation}
          style={{ maxHeight: 400, maxWidth: 400 }}
        />
        <Typography variant="subtitle1">
          <b>NO TIENES AUTORIZACIÓN ACCEDER A ESTE MÓDULO</b>
        </Typography>
        <Typography variant="subtitle2" color="gray">
          Prueba usando la navegación para una mejor experiencia de usuario
        </Typography>
        <br />
        <Button variant="contained" onClick={() => window.history.back()}>
          VOLVER
        </Button>
      </Grid>
    </Grid>
  );
};

export default Page404;
