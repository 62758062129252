import "react-perfect-scrollbar/dist/css/styles.css";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import ThemeConfig from "./theme";
import routes from "./routes";
import ApplicationRouter from "./components/ApplicationRouter";
import NapClassProvider from "./context";
import GlobalStyles from "./theme/GlobalStyles";
import moment from "moment-timezone";
import ExamProvider from "context/exams";
import StorageService from "auth/StorageService";
import { SESSION_MODE } from "config/session";

const App = () => {
  moment.tz.setDefault("America/Lima");
  const mode = StorageService.get(SESSION_MODE);

  return (
    <NapClassProvider>
      <ExamProvider>
        <ThemeConfig mode={mode ? JSON.parse(mode) : "light"}>
          <GlobalStyles />
          <Router>
            <Switch>
              {routes.map((route, index) => (
                <ApplicationRouter
                  key={index}
                  exact={route.exact}
                  path={route.path}
                  listRoles={route.listRoles}
                  component={route.component}
                  isPrivate={route.isPrivate}
                />
              ))}
            </Switch>
          </Router>
        </ThemeConfig>
      </ExamProvider>
    </NapClassProvider>
  );
};

export default App;
