import { useState, useEffect } from "react";
import SelectSchools from "components/selects/SelectSchools";
import { notification } from "antd";
import StorageService from "auth/StorageService";
import { SESSION_USER } from "config/session";
import userDetails from "utils/userDetails";
import useSchools from "hooks/useSchools";

const ChangeSchool = () => {
  const [selected, setSelected] = useState("");
  const { load: loading, schools: data } = useSchools();

  useEffect(() => {
    if (!Boolean(userDetails.school)) {
      if (data.length > 0) {
        let res: any = StorageService.get(SESSION_USER);
        if (res) {
          let tmp = JSON.parse(res);
          tmp.value.data["school"] = data[0];
          StorageService.set(SESSION_USER, tmp);
          setSelected(tmp.value.data["school"]._id);
          window.location.reload();
        }
      }
    } else {
      setSelected(userDetails.school);
    }
  }, [data]);

  const handleChangeSchool = (e: string) => {
    if (!data) return;

    try {
      let res: any | null = StorageService.get(SESSION_USER);
      let finder = data?.find((el: { _id: string }) => el._id === e);
      res = JSON.parse(res);

      if (res) {
        res.value.data["school"] = finder;
        StorageService.set(SESSION_USER, res);
        setSelected(e);
        // window.location.href = "/";
        window.location.reload();
      }
    } catch (error) {
      notification["error"]({
        message: "Oops!",
        description: `Ocurrió un error. ${error}`,
      });
    }
  };

  return (
    <SelectSchools
      value={selected}
      onChange={(e: string) => handleChangeSchool(e)}
      data={data}
      loading={loading}
    />
  );
};

export default ChangeSchool;
