import React from "react";

// material-ui
import { Divider, List, Typography } from "@mui/material";

// project imports
import NavItem from "./../NavItem";
import NavCollapse from "./../NavCollapse";

//-----------------------|| SIDEBAR MENU LIST GROUP ||-----------------------//

interface PropsItem {
  item: {
    children: Object[];
    title: string;
  };
}

const NavGroup = ({ item }: PropsItem) => {
  // menu list collapse & items
  const items = item.children.map((menu: any) => {
    switch (menu.type) {
      case "collapse":
        return <NavCollapse key={menu.id} menu={menu} level={1} />;
      case "item":
        return <NavItem key={menu.id} item={menu} level={1} />;
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <React.Fragment>
      <List
        subheader={
          item.title && (
            <Typography variant="caption" display="block" gutterBottom>
              {item.title}
            </Typography>
          )
        }
      >
        {items}
      </List>

      {/* group divider */}
      <Divider />
    </React.Fragment>
  );
};

export default NavGroup;
