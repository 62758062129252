import React from "react";

// material-ui
import { makeStyles } from "@mui/styles";
import {
  Box,
  Drawer,
  Hidden,
  Typography,
  Avatar,
  FormHelperText,
  Divider,
} from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";
import userDetails from "utils/userDetails";
// project imports
import MenuList from "./MenuList";
import formatRole from "utils/formatRole";
import { PropsStyles } from "interfaces";

// style constant
const useStyles = makeStyles((theme: PropsStyles) => ({
  drawerPaper: {
    width: 240,
    zIndex: "999 !important",
  },
  ScrollHeight: {
    height: "calc(100vh - 88px)",
    paddingLeft: "16px",
    paddingRight: "16px",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 56px)",
    },
  },
  boxContainer: {
    margin: "0 auto",
  },
}));

interface PropsTypes {
  drawerOpen: boolean;
  drawerToggle: Function;
  matchUpMd: boolean;
}

//-----------------------|| SIDEBAR DRAWER ||-----------------------//

const Sidebar = ({ drawerOpen, drawerToggle, matchUpMd }: PropsTypes) => {
  const classes = useStyles(true);

  const drawer = (
    <React.Fragment>
      <Box pt={3} pl={1} pr={1} pb={1}>
        <Avatar
          style={{ height: 80, width: 80 }}
          className={classes.boxContainer}
          src={
            userDetails.profile_picture
              ? userDetails.profile_picture
              : "/maskable.png"
          }
        />
        <br />
        <Typography variant="subtitle2" align="center">
          <b>
            {userDetails.f_name} {userDetails.l_name}
          </b>
        </Typography>
        <FormHelperText style={{ textAlign: "center" }}>
          {formatRole(userDetails.rol)}
        </FormHelperText>
      </Box>
      <Divider />
      <Hidden mdDown>
        <PerfectScrollbar component="div" className={classes.ScrollHeight}>
          {MenuList()}
        </PerfectScrollbar>
      </Hidden>
      <Hidden mdUp>
        <Box sx={{ px: 2 }}>{MenuList()}</Box>
      </Hidden>
    </React.Fragment>
  );

  return (
    <nav>
      <Drawer
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor="left"
        open={drawerOpen}
        onClose={() => drawerToggle()}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{ keepMounted: true }}
      >
        {drawer}
      </Drawer>
    </nav>
  );
};

export default Sidebar;
