import axios from "axios";
import { API_NAPCLASS } from "config/api.config";
import userDetails from "utils/userDetails";

const { school, year, token } = userDetails;

export const getUnits = async () => {
  let res = await axios.get(`${API_NAPCLASS}/unit/${school}/${year}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const uploadUnits = async (data: Object, period: string) => {
  let res = await axios.post(
    `${API_NAPCLASS}/unit/import/${school}/${period}/${year}`,
    data,
    { headers: { Authorization: token } }
  );
  return res.data;
};

export const putUnits = async (data: Object, id: string) => {
  let res = await axios.put(`${API_NAPCLASS}/unit/${id}`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};
