import axios from "axios";
import { API_NAPCLASS } from "config/api.config";
import userDetails from "utils/userDetails";

const { token, school } = userDetails;

export const getCalendarEvents = async (id: number, rol: string) => {
  const res = await axios.get(`${API_NAPCLASS}/${rol}/events/${id}/${school}`, {
    headers: { Authorization: token },
  });
  return res.data;
};
