import { API_NAPCLASS } from "config/api.config";
import StorageService from "./StorageService";
import SessionTimeService from "./SessionTimeService";
import { SESSION_USER } from "config/session";
import axios from "axios";

class AuthenticationService {
  static isAuthenticated = false;

  static login(obj: Object, idSchool: string) {
    const result = new Promise((resolve, reject) => {
      axios
        .post(`${API_NAPCLASS}/auth/login/${idSchool}`, obj)
        .then((res) => {
          this.isAuthenticated = true;

          StorageService.set(SESSION_USER, {
            expiresAt: SessionTimeService.estimatedTime(),
            value: res.data,
          });
          resolve(res.data);
        })
        .catch(
          ({
            response: {
              data: { msg },
            },
          }) => {
            reject(new Error(`Ocurrió un error. ${msg}`));
          }
        );
    });
    return result;
  }

  static logout() {
    return new Promise((resolve: Function, reject: Function) => {
      this.isAuthenticated = false;

      StorageService.remove(SESSION_USER);
      resolve();
    });
  }
}

export default AuthenticationService;
