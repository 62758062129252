import axios from "axios";
import { API_NAPCLASS } from "config/api.config";
import userDetails from "utils/userDetails";

const { school, year, token } = userDetails;

export const getNoteRange = async () => {
  let res = await axios.get(`${API_NAPCLASS}/notes-range/${school}/${year}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const postNoteRange = async (data: Object) => {
  let res = await axios.post(
    `${API_NAPCLASS}/notes-range/${school}/${year}`,
    data,
    { headers: { Authorization: token } }
  );
  return res.data;
};
