import { Link, useLocation } from "react-router-dom";
// material-ui
import { makeStyles } from "@mui/styles";
import { ListItemText, Typography, Box } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
// assets
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { PropsStyles } from "interfaces";

// style constant
const useStyles = makeStyles((theme: PropsStyles) => ({
  listItem: {
    alignItems: "center",
  },
  listItemNoBack: {
    backgroundColor: "transparent !important",
    "&:hover": {
      color: "#23286b !important",
    },
  },
  hoverLI: {
    "&:hover": {
      color: "#23286b !important",
    },
  },
  color: { color: theme.palette.primary.main },
}));

//-----------------------|| SIDEBAR MENU LIST ITEMS ||-----------------------//

interface PropsTypes {
  item: {
    url: string;
    icon: any;
    title: string;
  };
  level: number;
}

const NavItem = ({ item, level }: PropsTypes) => {
  const classes = useStyles(true);
  const location = useLocation();
  let active = location.pathname === item.url;

  const Icon = item.icon;
  const itemIcon = item.icon ? (
    <Icon
      stroke={1.5}
      size="1.3rem"
      style={{ color: active ? classes.color : "#000" }}
    />
  ) : (
    <FiberManualRecordIcon
      style={{ width: 10, height: 10 }}
      // fontSize={level > 0 ? "inherit" : "default"}
      // color={active ? "primary" : "active"}
    />
  );

  return (
    <ListItemButton
      component={Link}
      // disabled={item.disabled}
      className={level > 1 ? classes.listItemNoBack : classes.hoverLI}
      sx={{ borderRadius: "10px" }}
      selected={active}
      to={{ pathname: item.url }}
      style={{
        paddingLeft: level * 23 + "px",
        alignItems: "center",
        textDecoration: "none",
        color: active ? "#23286b" : "",
      }}
    >
      <Box pr={1}>{itemIcon}</Box>
      <ListItemText
        primary={<Typography variant="body2">{item.title}</Typography>}
      />
    </ListItemButton>
  );
};

export default NavItem;
