import { lazy } from "react";
import Loadable from "routes/Loadable";

const Page404 = Loadable(lazy(() => import("pages/Page404")));
const Login = Loadable(lazy(() => import("pages/Login")));
const LandingPage = Loadable(lazy(() => import("pages/LandingPage")));
const Dashboard = Loadable(lazy(() => import("pages/views/Dashboard")));
const Schools = Loadable(lazy(() => import("pages/views/Schools")));
const Users = Loadable(lazy(() => import("pages/views/Users")));
const Profile = Loadable(lazy(() => import("pages/views/Profile")));
const Rooms = Loadable(lazy(() => import("pages/views/Rooms")));
const Postulations = Loadable(lazy(() => import("pages/views/Postulations")));
const Courses = Loadable(lazy(() => import("pages/views/Courses")));
const ResetPass = Loadable(lazy(() => import("pages/Login/components/Reset")));
const Assigment = Loadable(lazy(() => import("pages/views/Assignment")));
const Periods = Loadable(lazy(() => import("pages/views/Periods")));
const NoteRange = Loadable(lazy(() => import("pages/views/NoteRange")));
const Notices = Loadable(lazy(() => import("pages/views/Notices")));
const Messages = Loadable(lazy(() => import("pages/views/Messages")));
const MyCourses = Loadable(lazy(() => import("pages/views/MyCourses/List")));
const Options = Loadable(lazy(() => import("pages/views/MyCourses/components/Options")));
const Settings = Loadable(lazy(() => import("pages/views/MyCourses/components/Settings")));
const Plan = Loadable(lazy(() => import("pages/views/MyCourses/components/Plan")));
const Units = Loadable(lazy(() => import("pages/views/Units")));
const Lessons = Loadable(lazy(() => import("pages/views/MyCourses/components/Lessons")));
const Participants = Loadable(lazy(() => import("pages/views/MyCourses/components/Participants")));
const Participations = Loadable(lazy(() => import("pages/views/MyCourses/components/Participations")));
const Attendance = Loadable(lazy(() => import("pages/views/MyCourses/components/Attendance")));
const Events = Loadable(lazy(() => import("pages/views/MyCourses/components/Events")));
const Exams = Loadable(lazy(() => import("pages/views/MyCourses/components/Exams")));
const TakeExam = Loadable(lazy(() => import("pages/views/MyCourses/components/Exams/Take")));
const Review = Loadable(lazy(() => import("pages/views/MyCourses/components/Exams/Bank/Review")));
const TakeQr = Loadable(lazy(() => import("pages/views/AssistanceGeneral/TakeQr")));

const Calendar = Loadable(lazy(() => import("pages/views/Calendar")));
const MyChildren = Loadable(lazy(() => import("pages/views/MyChildren")));
const InfoChildren = Loadable(lazy(() => import("pages/views/MyChildren/components/Content")));
const Pending = Loadable(lazy(() => import("pages/views/Pending")));
const Tasks = Loadable(lazy(() => import("pages/views/MyCourses/components/Tasks")));
const TaskQualifications = Loadable(lazy(() => import("pages/views/MyCourses/components/Tasks/TaskQualifications")));
const Reports = Loadable(lazy(() => import("pages/views/MyCourses/components/Reports")));
const ReportsTasks = Loadable(lazy(() => import("pages/views/MyCourses/components/Reports/Tasks")));
const ReportsAssists = Loadable(lazy(() => import("pages/views/MyCourses/components/Reports/Assists")));
const ReportsExams = Loadable(lazy(() => import("pages/views/MyCourses/components/Reports/Exams")));
const ReportsParticipations = Loadable(lazy(() => import("pages/views/MyCourses/components/Reports/Participations")));
const ReportsNotes = Loadable(lazy(() => import("pages/views/MyCourses/components/Reports/Notes")));
const RegisterNotes = Loadable(lazy(() => import("pages/views/MyCourses/components/Reports/Notes/Register")));
const ReportsCompetencies = Loadable(lazy(() => import("pages/views/MyCourses/components/Reports/Competencies")));
const Competences = Loadable(lazy(() => import("pages/views/MyCourses/components/Competencies")));
const TeacherCompetences = Loadable(
  lazy(() => import("pages/views/MyCourses/components/Competencies/TeacherCompetences"))
);
const AssistanceGeneral = Loadable(lazy(() => import("pages/views/AssistanceGeneral")));
const TakeAssistance = Loadable(lazy(() => import("pages/views/AssistanceGeneral/Take")));

const Group = Loadable(lazy(() => import("pages/views/MyCourses/components/Group")));
const ReportsAdmin = Loadable(lazy(() => import("pages/views/Reports")));
const CompetenceNotesPeriod = Loadable(lazy(() => import("pages/views/Reports/Competences/Period")));
const CompetenceNotesRoom = Loadable(lazy(() => import("pages/views/Reports/Competences/Room")));
const CompetenceNotesYear = Loadable(lazy(() => import("pages/views/Reports/Competences/Year")));
const CompetenceNotesByStudent = Loadable(lazy(() => import("pages/views/Reports/Competences/components/Detail")));
const CompetenceNotesByYear = Loadable(lazy(() => import("pages/views/Reports/Competences/components/DetailYear")));
const ConsolidatedSpecific = Loadable(lazy(() => import("pages/views/Reports/Consolidated/SpecificCompetences")));
const ConsolidatedArea = Loadable(lazy(() => import("pages/views/Reports/Consolidated/AreaCompetences")));
const ConsolidatedTransversal = Loadable(lazy(() => import("pages/views/Reports/Consolidated/TransversalCompetences")));
const ReportsMerit = Loadable(lazy(() => import("pages/views/Reports/Merit")));
const ReportsNotas = Loadable(lazy(() => import("pages/views/Reports/Notas")));

const routes = [
  {
    path: "/:url/login",
    exact: true,
    listRoles: [],
    component: Login,
    isPrivate: false,
    name: "login",
  },
  {
    path: "/:url/reset/password",
    exact: true,
    listRoles: [],
    component: ResetPass,
    isPrivate: false,
    name: "resetpassword",
  },
  {
    path: "/home",
    exact: true,
    listRoles: [],
    component: LandingPage,
    isPrivate: false,
    name: "home",
  },
  {
    path: "/",
    exact: true,
    listRoles: [
      "student",
      "family",
      "teacher",
      "admin",
      "superadmin",
      "director",
      "psicologa",
      "coordinator",
      "librarian",
      "nurse",
      "assistance",
    ],
    component: Dashboard,
    isPrivate: true,
    name: "index",
  },
  {
    path: "/profile",
    exact: true,
    listRoles: [
      "student",
      "family",
      "teacher",
      "admin",
      "superadmin",
      "director",
      "psicologa",
      "coordinator",
      "librarian",
      "nurse",
    ],
    component: Profile,
    isPrivate: true,
    name: "profile",
  },
  {
    path: "/schools",
    exact: true,
    listRoles: ["superadmin"],
    component: Schools,
    isPrivate: true,
    name: "schools",
  },
  {
    path: "/users/:role",
    exact: true,
    listRoles: ["admin", "superadmin"],
    component: Users,
    isPrivate: true,
    name: "users",
  },
  {
    path: "/rooms",
    exact: true,
    listRoles: ["admin", "superadmin"],
    component: Rooms,
    isPrivate: true,
    name: "rooms",
  },
  {
    path: "/assistance-general",
    exact: true,
    listRoles: ["admin", "superadmin", "assistance", "coordinator"],
    component: AssistanceGeneral,
    isPrivate: true,
    name: "assistance-general",
  },
  {
    path: "/assistance-general/take/:dateParam",
    exact: true,
    listRoles: ["admin", "superadmin", "assistance"],
    component: TakeAssistance,
    isPrivate: true,
    name: "assistance-general-take",
  },
  {
    path: "/assistance-general/take-qr/:dateParam",
    exact: true,
    listRoles: ["admin", "superadmin", "assistance"],
    component: TakeQr,
    isPrivate: true,
    name: "assistance-general-take-qr",
  },
  {
    path: "/postulations",
    exact: true,
    listRoles: ["admin", "superadmin"],
    component: Postulations,
    isPrivate: true,
    name: "postulations",
  },
  {
    path: "/courses",
    exact: true,
    listRoles: ["admin", "superadmin"],
    component: Courses,
    isPrivate: true,
    name: "courses",
  },
  {
    path: "/assigment",
    exact: true,
    listRoles: ["admin", "superadmin"],
    component: Assigment,
    isPrivate: true,
    name: "assigment",
  },
  {
    path: "/report",
    exact: true,
    listRoles: ["admin", "superadmin", "assistance", "coordinator"],
    component: ReportsAdmin,
    isPrivate: true,
    name: "reportsadmin",
  },
  {
    path: "/reportcard/competences/:type",
    exact: true,
    listRoles: ["admin", "superadmin", "assistance", "coordinator"],
    component: CompetenceNotesPeriod,
    isPrivate: true,
    name: "notescompetences",
  },
  {
    path: "/reportcard/competences/period/:studentId/:periodId",
    exact: true,
    listRoles: ["admin", "superadmin", "assistance"],
    component: CompetenceNotesByStudent,
    isPrivate: true,
    name: "notescompetencesdetails",
  },
  {
    path: "/reportcard/competences/year/:studentId",
    exact: true,
    listRoles: ["admin", "superadmin", "assistance", "coordinator"],
    component: CompetenceNotesByYear,
    isPrivate: true,
    name: "notescompetencesdetails",
  },
  {
    path: "/reportcard/competences/period/room",
    exact: true,
    listRoles: ["admin", "superadmin", "assistance"],
    component: CompetenceNotesRoom,
    isPrivate: true,
    name: "notescompetences",
  },
  {
    path: "/reportcard/competences/period/year",
    exact: true,
    listRoles: ["admin", "superadmin", "assistance", "coordinator"],
    component: CompetenceNotesYear,
    isPrivate: true,
    name: "notescompetences",
  },
  {
    path: "/reportcard/consolidated/specific",
    exact: true,
    listRoles: ["admin", "superadmin", "assistance", "coordinator"],
    component: ConsolidatedSpecific,
    isPrivate: true,
    name: "consolidatedspecific",
  },
  {
    path: "/reportcard/consolidated/area",
    exact: true,
    listRoles: ["admin", "superadmin", "assistance", "coordinator"],
    component: ConsolidatedArea,
    isPrivate: true,
    name: "consolidatedarea",
  },
  {
    path: "/reportcard/notas",
    exact: true,
    listRoles: ["admin", "superadmin", "assistance", "coordinator"],
    component: ReportsNotas,
    isPrivate: true,
    name: "consolidatedarea",
  },
  {
    path: "/reportcard/consolidated/transversal",
    exact: true,
    listRoles: ["admin", "superadmin", "assistance", "coordinator"],
    component: ConsolidatedTransversal,
    isPrivate: true,
    name: "consolidatedtransversal",
  },
  {
    path: "/periods",
    exact: true,
    listRoles: ["admin", "superadmin"],
    component: Periods,
    isPrivate: true,
    name: "periods",
  },
  {
    path: "/units",
    exact: true,
    listRoles: ["admin", "superadmin"],
    component: Units,
    isPrivate: true,
    name: "units",
  },
  {
    path: "/noterange",
    exact: true,
    listRoles: ["admin", "superadmin"],
    component: NoteRange,
    isPrivate: true,
    name: "noterange",
  },
  {
    path: "/competences",
    exact: true,
    listRoles: ["admin", "superadmin"],
    component: Competences,
    isPrivate: true,
    name: "competences",
  },
  {
    path: "/teacher/courses/competences/:idCourse",
    exact: true,
    listRoles: ["teacher", "admin"],
    component: TeacherCompetences,
    isPrivate: true,
    name: "competences",
  },
  {
    path: "/courses/group",
    exact: true,
    listRoles: ["admin", "superadmin"],
    component: Group,
    isPrivate: true,
    name: "group",
  },
  {
    path: "/notices",
    exact: true,
    listRoles: [
      "student",
      "family",
      "teacher",
      "admin",
      "superadmin",
      "director",
      "psicologa",
      "coordinator",
      "librarian",
      "nurse",
    ],
    component: Notices,
    isPrivate: true,
    name: "notices",
  },
  {
    path: "/messages",
    exact: true,
    listRoles: [
      "student",
      "family",
      "teacher",
      "admin",
      "superadmin",
      "director",
      "psicologa",
      "coordinator",
      "librarian",
      "nurse",
    ],
    component: Messages,
    isPrivate: true,
    name: "messages",
  },
  {
    path: "/teacher/courses",
    exact: true,
    listRoles: ["teacher", "admin"],
    component: MyCourses,
    isPrivate: true,
    name: "MyCourses",
  },
  {
    path: "/coordinator/courses",
    exact: true,
    listRoles: ["coordinator"],
    component: MyCourses,
    isPrivate: true,
    name: "MyCourses",
  },
  {
    path: "/student/courses",
    exact: true,
    listRoles: ["student"],
    component: MyCourses,
    isPrivate: true,
    name: "Students",
  },
  {
    path: "/teacher/courses/:idCourse",
    exact: true,
    listRoles: ["teacher", "admin"],
    component: Options,
    isPrivate: true,
    name: "options",
  },
  {
    path: "/student/courses/:idCourse",
    exact: true,
    listRoles: ["student"],
    component: Options,
    isPrivate: true,
    name: "options",
  },
  {
    path: "/coordinator/courses/:idCourse",
    exact: true,
    listRoles: ["coordinator"],
    component: Options,
    isPrivate: true,
    name: "options",
  },
  {
    path: "/student/courses/participants/:idCourse",
    exact: true,
    listRoles: ["student"],
    component: Participants,
    isPrivate: true,
    name: "participants",
  },
  {
    path: "/teacher/courses/config/:idCourse",
    exact: true,
    listRoles: ["teacher", "admin"],
    component: Settings,
    isPrivate: true,
    name: "coursesettings",
  },
  {
    path: "/teacher/courses/plan/:idCourse",
    exact: true,
    listRoles: ["teacher", "admin"],
    component: Plan,
    isPrivate: true,
    name: "courseplan",
  },
  {
    path: "/coordinator/courses/plan/:idCourse",
    exact: true,
    listRoles: ["coordinator"],
    component: Plan,
    isPrivate: true,
    name: "courseplan",
  },
  {
    path: "/teacher/courses/lessons/:idCourse",
    exact: true,
    listRoles: ["teacher", "admin"],
    component: Lessons,
    isPrivate: true,
    name: "lessons",
  },
  {
    path: "/coordinator/courses/lessons/:idCourse",
    exact: true,
    listRoles: ["coordinator"],
    component: Lessons,
    isPrivate: true,
    name: "lessons",
  },
  {
    path: "/coordinator/courses/participants/:idCourse",
    exact: true,
    listRoles: ["coordinator"],
    component: Participants,
    isPrivate: true,
    name: "participants",
  },
  {
    path: "/teacher/courses/participants/:idCourse",
    exact: true,
    listRoles: ["teacher", "admin"],
    component: Participants,
    isPrivate: true,
    name: "participants",
  },
  {
    path: "/teacher/courses/participations/:idCourse",
    exact: true,
    listRoles: ["teacher", "admin"],
    component: Participations,
    isPrivate: true,
    name: "participations",
  },
  {
    path: "/coordinator/courses/participations/:idCourse",
    exact: true,
    listRoles: ["coordinator"],
    component: Participations,
    isPrivate: true,
    name: "participations",
  },
  {
    path: "/teacher/courses/event/:type/:idCourse",
    exact: true,
    listRoles: ["teacher", "admin"],
    component: Events,
    isPrivate: true,
    name: "events",
  },
  {
    path: "/student/courses/event/:type/:idCourse",
    exact: true,
    listRoles: ["student"],
    component: Events,
    isPrivate: true,
    name: "events",
  },
  {
    path: "/coordinator/courses/event/:type/:idCourse",
    exact: true,
    listRoles: ["coordinator"],
    component: Events,
    isPrivate: true,
    name: "events",
  },
  {
    path: "/teacher/courses/exams/:idCourse",
    exact: true,
    listRoles: ["teacher", "admin"],
    component: Exams,
    isPrivate: true,
    name: "exams",
  },
  {
    path: "/teacher/courses/exam-review/:idExam/:idCourse",
    exact: true,
    listRoles: ["teacher", "admin"],
    component: Review,
    isPrivate: true,
    name: "reviewexams",
  },
  {
    path: "/student/courses/exams/:idCourse",
    exact: true,
    listRoles: ["student"],
    component: Exams,
    isPrivate: true,
    name: "exams",
  },
  {
    path: "/teacher/courses/attendance/:idCourse",
    exact: true,
    listRoles: ["teacher", "admin"],
    component: Attendance,
    isPrivate: true,
    name: "attendance",
  },
  {
    path: "/coordinator/courses/attendance/:idCourse",
    exact: true,
    listRoles: ["coordinator"],
    component: Attendance,
    isPrivate: true,
    name: "attendance",
  },
  {
    path: "/admin/courses/attendance/:idCourse",
    exact: true,
    listRoles: ["admin"],
    component: Attendance,
    isPrivate: true,
    name: "attendance",
  },
  {
    path: "/teacher/courses/reports/:idCourse",
    exact: true,
    listRoles: ["teacher", "admin"],
    component: Reports,
    isPrivate: true,
    name: "Reports",
  },
  {
    path: "/student/courses/take-exam/:idExam",
    exact: true,
    listRoles: ["student"],
    component: TakeExam,
    isPrivate: true,
    name: "TakeExam",
  },
  {
    path: "/calendar",
    exact: true,
    listRoles: ["student", "teacher"],
    component: Calendar,
    isPrivate: true,
    name: "Calendar",
  },
  {
    path: "/pending",
    exact: true,
    listRoles: ["teacher", "student"],
    component: Pending,
    isPrivate: true,
    name: "Pending",
  },
  {
    path: "/family/my-children",
    exact: true,
    listRoles: ["family"],
    component: MyChildren,
    isPrivate: true,
    name: "My Children",
  },
  {
    path: "/family/my-children/:idChild",
    exact: true,
    listRoles: ["family"],
    component: InfoChildren,
    isPrivate: true,
    name: "My Children",
  },
  {
    path: "/work/response/:idWork",
    exact: true,
    listRoles: ["student"],
    component: Tasks,
    isPrivate: true,
    name: "Response",
  },
  {
    path: "/work/review/:idWork",
    exact: true,
    listRoles: ["teacher", "admin"],
    component: TaskQualifications,
    isPrivate: true,
    name: "Review",
  },
  {
    path: "/report/task/:idCourse",
    exact: true,
    listRoles: ["teacher", "admin"],
    component: ReportsTasks,
    isPrivate: true,
    name: "Report Task",
  },
  {
    path: "/reportcard/merit",
    exact: true,
    listRoles: ["admin", "superadmin", "assistance", "coordinator"],
    component: ReportsMerit,
    isPrivate: true,
    name: "Report Merit",
  },
  {
    path: "/report/assists/:idCourse",
    exact: true,
    listRoles: ["teacher", "admin"],
    component: ReportsAssists,
    isPrivate: true,
    name: "Report Assistis",
  },
  {
    path: "/report/exams/:idCourse",
    exact: true,
    listRoles: ["teacher", "admin"],
    component: ReportsExams,
    isPrivate: true,
    name: "Report Exams",
  },
  {
    path: "/report/participations/:idCourse",
    exact: true,
    listRoles: ["teacher", "admin"],
    component: ReportsParticipations,
    isPrivate: true,
    name: "Report Exams",
  },
  {
    path: "/report/notes/:idCourse",
    exact: true,
    listRoles: ["teacher", "admin"],
    component: ReportsNotes,
    isPrivate: true,
    name: "Report Notes",
  },
  {
    path: "/report/notes/register/:idCourse",
    exact: true,
    listRoles: ["teacher", "admin"],
    component: RegisterNotes,
    isPrivate: true,
    name: "Report Notes",
  },
  {
    path: "/report/competencies/:idCourse",
    exact: true,
    listRoles: ["teacher", "admin"],
    component: ReportsCompetencies,
    isPrivate: true,
    name: "Report Competencies",
  },
  {
    path: "",
    exact: true,
    listRoles: [],
    component: Page404,
    isPrivate: false,
    name: "",
  },
];

export default routes;
