import { SESSION_USER } from "config/session";
import StorageService from "auth/StorageService";
import moment from "moment-timezone";

let all: any = StorageService.get(SESSION_USER) ? StorageService.get(SESSION_USER) : null;

let tmp = JSON.parse(all)?.value ?? null;

let user = Boolean(all) ? tmp?.data : null;
let token = Boolean(all) ? tmp?.token : null;
let school = Boolean(all) ? tmp?.data?.school?._id : null;
let schoolInfo = Boolean(all) ? tmp?.data?.school : null;
let rol = Boolean(all) ? tmp?.data?.rol : null;
let l_name = Boolean(all) ? tmp?.data?.person?.l_name : null;
let f_name = Boolean(all) ? tmp?.data?.person?.f_name : null;
let rol_id = Boolean(all) ? tmp?.data?.person?._id : null;
let user_id = Boolean(all) ? tmp?.data?._id : null;
let profile_picture = Boolean(all) ? tmp?.data?.profile_picture : null;
let logo = Boolean(all) ? tmp?.data?.school?.logo ?? null : null;
let banner = Boolean(all) ? tmp?.data?.school?.banner ?? null : null;
let url = Boolean(all) ? tmp?.data?.school?.url : null;
let school_name = Boolean(all) ? tmp?.data?.school?.tradeName : null;
let t_doc = Boolean(all) ? tmp?.data?.person?.t_doc : null;
let cord = Boolean(all) ? tmp?.data?.person?.coord : false;
let year = Boolean(all) ? (tmp?.year ? tmp?.year : moment().year()) : null;
let logo_school = Boolean(all) ? tmp?.data?.school?.logo : null;
let school_levels = Boolean(all) ? tmp?.data?.school?.levels : null;
let school_shifts = Boolean(all) ? tmp?.data?.school?.shifts : null;
let person = Boolean(all) ? tmp?.data?.person : null;

const userDetails = {
  user,
  token,
  school,
  rol,
  l_name,
  f_name,
  rol_id,
  user_id,
  profile_picture,
  logo,
  banner,
  url,
  school_name,
  t_doc,
  cord,
  year,
  logo_school,
  school_levels,
  school_shifts,
  person,
  schoolInfo,
};

export default userDetails;
