import axios from "axios";
import { API_NAPCLASS } from "config/api.config";
import userDetails from "utils/userDetails";

const { user_id, token, rol, school } = userDetails;

export const getReceived = async () => {
  let res = await axios.get(
    `${API_NAPCLASS}/message/list-received/${user_id}`,
    { headers: { Authorization: token } }
  );
  return res.data;
};

export const getDrafts = async () => {
  let res = await axios.get(`${API_NAPCLASS}/message/list-draft/${user_id}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getSenders = async () => {
  let res = await axios.get(`${API_NAPCLASS}/message/list-senders/${user_id}`, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const getContacts = async () => {
  let res = await axios.get(
    `${API_NAPCLASS}/message/receivers/${user_id}/${school}/${rol}`,
    { headers: { Authorization: token } }
  );
  return res.data;
};

export const postMessage = async (data: Object) => {
  let res = await axios.post(`${API_NAPCLASS}/message/send-message`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};

export const postDraft = async (data: Object) => {
  let res = await axios.post(`${API_NAPCLASS}/message/save-message`, data, {
    headers: { Authorization: token },
  });
  return res.data;
};
